.root {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    padding-left: 37px;
    gap: calc(0.5 * var(--spacing));

    &.disabled {
        opacity: 0.4;

        .label {
            cursor: not-allowed;

            &:hover .radio::after {
                transform: scale(0) translateZ(0);
            }
        }

        .radio {
            cursor: not-allowed;

            &::before {
                border-color: var(--gray2);
            }
        }
    }
}

.label {
    @mixin standard;
    @mixin w400;
    cursor: pointer;

    &:hover .input {
        & + .radio {
            &::before {
                border-color: var(--gray2);
            }

            &::after {
                border-color: var(--gray2);
                transform: scale(0.5) translateZ(0);
            }
        }

        &:checked + .radio {
            &::before {
                border-color: var(--primaryColorDark);
            }

            &::after {
                border-color: var(--primaryColorDark);
                color: var(--primaryColorDark);
                transform: scale(1) translateZ(0);
            }
        }
    }
}

.input {
    position: absolute;
    opacity: 0;

    &:checked + .radio {
        &::before {
            border-color: var(--primaryColor);
        }

        &::after {
            border-color: var(--primaryColor);
            color: var(--primaryColor);
            transform: scale(1) translateZ(0);
        }
    }
}

.radio {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;

    &::before,
    &::after {
        position: absolute;
        content: "";
        transition: all 200ms var(--fastOutSlowIn);
    }

    &::before {
        width: 22px;
        height: 22px;
        border: 1px solid var(--gray2);
        border-radius: 50%;
    }

    &::after {
        top: 6px;
        left: 6px;
        border: 5px solid var(--gray2);
        border-radius: 50%;
        transform: scale(0) translateZ(0);
    }

    &.invalid::before {
        border-color: var(--signalRed);
    }
}
