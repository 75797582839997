.root {
    display: flex;
    flex-direction: column;

    &.reversed {
        flex-direction: column-reverse;
    }

    .wrapper {
        display: grid;
        grid-template-rows: 1fr;
        transition: grid-template-rows 500ms var(--fastOutSlowIn);

        .body {
            overflow: hidden;
            transition: 500ms var(--fastOutSlowIn);
        }
    }

    &.collapsed {
        .wrapper {
            grid-template-rows: 0fr;

            .body {
                opacity: 0;
            }
        }
    }
}
