.root {
    @mixin small;
    @mixin w400;
    display: flex;
    flex-direction: column;
    gap: var(--spacing);
}
.participant {
    margin-bottom: calc(2 * var(--spacing));
}
