.container {
    svg {
        width: 100%;
        height: 100%;
        fill: var(--primaryColor);
    }

    &.confirmation svg {
        fill: var(--signalGreen);
    }

    &.alert svg {
        fill: var(--signalOrange);
    }
}

.header {
    height: 122px;
}

.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    font-size: calc(1rem / 16 * 80);
    transform: translate(-50%, -50%);
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--spacing);
    font-size: calc(1rem / 16 * 20);
    line-height: normal;
}
