.root {
    padding: calc(3 * var(--spacing)) 0;
    text-align: center;
}

.header {
    @mixin smaller;
    padding-bottom: calc(1.75 * var(--spacing));
    font-size: calc(1rem / 16 * 10);
}

.body {
    position: relative;
}

.scrollButton {
    position: absolute;
    z-index: var(--zIndexOverlay);
    top: -19px;
    display: flex;
    width: 40px;
    height: 60px;
    align-items: center;
    color: var(--primaryColor);
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition:
        color 200ms var(--fastOutSlowIn),
        opacity 300ms var(--fastOutSlowIn);

    &:hover {
        color: var(--primaryColorDark);
    }

    &.visible {
        opacity: 1;
        pointer-events: auto;

        .arrow {
            transform: scale(1);
        }
    }

    &.left {
        left: 0;
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.85),
            #fff
        );

        .arrow {
            left: -8px;
        }
    }

    &.right {
        right: 0;
        justify-content: flex-end;
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.85),
            #fff
        );

        .arrow {
            right: -8px;
        }
    }
}

.arrow {
    position: relative;
    top: 1px;
    transform: scale(0);
    transition: transform 300ms var(--fastOutSlowIn);
}

.steps {
    position: relative;
    display: flex;
    overflow: hidden;
}

.step {
    display: grid;
    min-width: 80px;
    flex: 1;
    align-content: flex-start;

    &.active {
        .button {
            border-color: var(--primaryColor);
            background-color: var(--primaryColor);
            color: #fff;

            &:hover {
                border-color: var(--primaryColorDark);
                background-color: var(--primaryColorDark);
                color: #fff;
            }
        }

        .line.before {
            background: var(--signalGreen);
        }
    }

    &.completed {
        .button {
            border-color: var(--signalGreen);
            background-color: var(--signalGreen);
            color: #fff;
            cursor: pointer;

            &:hover {
                border-color: var(--signalGreenActive);
                background-color: var(--signalGreenActive);
                color: #fff;
            }
        }

        .line {
            background: var(--signalGreen);
        }

        .label {
            cursor: pointer;
        }
    }

    &:first-child .line.before {
        visibility: hidden;
    }

    &:last-child .line.after {
        visibility: hidden;
    }
}

.button {
    display: flex;
    width: calc(3 * var(--spacing));
    height: calc(3 * var(--spacing));
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 1px solid var(--primaryColor);
    border-radius: 100%;
    background: transparent;
    color: var(--primaryColor);
    font-family: inherit;
    font-size: calc(1rem / 16 * 14);
    font-weight: 600;
    grid-area: 1 / 1;
    justify-self: center;
    outline: none;
    transition: all 150ms ease-in-out;
}

.line {
    width: 35%;
    height: 1px;
    align-self: center;
    background: var(--gray4);
    grid-area: 1 / 1;
    transition: all 200ms ease;

    &.after {
        justify-self: flex-end;
    }
}

.label {
    @mixin small;
    margin: calc(1.75 * var(--spacing)) var(--spacing);
    cursor: default;
    font-weight: 400;
    grid-area: 2 / 1;
    text-align: center;
    transition: all 150ms ease-in-out;
}
