.select {
    :global(.select__control) {
        min-height: calc(6 * var(--spacing));
        border-color: var(--gray4);
        border-radius: 5px;
        background: #fff;
        cursor: pointer;
        transition: all 200ms var(--fastOutSlowIn);

        &:hover {
            border-color: var(--gray3);

            :global(.select__dropdown-indicator) {
                &::before,
                &::after {
                    background: var(--primaryColorDark);
                }
            }
        }
    }

    :global(.select__control--is-focused),
    :global(.select__control--menu-is-open) {
        border-color: var(--primaryColor);
        box-shadow: 0 0 4px 0 rgba(51, 51, 51, 0.1);

        &:hover {
            border-color: var(--primaryColor);
        }
    }

    :global(.select__control--menu-is-open).open {
        border-radius: 5px 5px 0 0;

        :global(.select__placeholder),
        :global(.select__single-value) {
            color: var(--primaryColor);
        }

        :global(.select__dropdown-indicator) {
            &::before {
                transform: rotate(-45deg);
            }

            &::after {
                transform: rotate(45deg);
            }
        }
    }

    :global(.select__control--is-disabled) {
        border-color: var(--gray5);
        cursor: not-allowed;
        pointer-events: auto;

        &:hover {
            border-color: var(--gray5);

            :global(.select__dropdown-indicator) {
                &::before,
                &::after {
                    background: var(--gray5);
                }
            }
        }

        :global(.select__placeholder) {
            color: var(--gray5);
        }

        :global(.select__dropdown-indicator) {
            &::before,
            &::after {
                background: var(--gray5);
            }
        }
    }

    :global(.select__placeholder) {
        color: var(--gray5);
    }

    :global(.select__value-container) {
        padding-right: 0;
        padding-left: calc(2 * var(--spacing));
    }

    :global(.select__input) {
        caret-color: var(--primaryColor);
    }

    :global(.select__clear-indicator) {
        color: var(--signalGray);

        &:hover {
            color: var(--signalGrayActive);
        }
    }

    :global(.select__indicator-separator) {
        background: var(--gray6);
    }

    :global(.select__dropdown-indicator) {
        position: relative;

        &::before,
        &::after {
            position: absolute;
            top: 50%;
            width: 10px;
            height: 2px;
            border-radius: 2px;
            background: var(--primaryColor);
            content: "";
            transition: all 200ms var(--fastOutSlowIn);
        }

        &::before {
            left: 10px;
            transform: rotate(45deg);
        }

        &::after {
            left: 16px;
            transform: rotate(-45deg);
        }

        svg {
            visibility: hidden;
        }
    }

    :global(.select__menu) {
        z-index: calc(var(--zIndexOverlay) + 1);
        border-radius: 0 0 5px 5px;
        border-bottom: 1px solid var(--gray6);
        margin-top: -1px;
        box-shadow: 0 0 4px 0 rgba(51, 51, 51, 0.1);
        opacity: 0;
        transform: scaleY(0);
        transform-origin: top;
        transition: all 200ms var(--fastOutSlowIn);

        &.open {
            opacity: 1;
            transform: scaleY(1);
        }
    }

    :global(.select__menu-notice),
    :global(.select__option) {
        display: flex;
        align-items: center;
        padding: calc(1.45 * var(--spacing)) calc(2 * var(--spacing));
        border: 1px solid var(--gray6);
        border-bottom: none;
    }

    :global(.select__menu-notice) {
        color: var(--gray3);
    }

    :global(.select__menu-list) {
        padding: 0;
    }

    :global(.select__option) {
        cursor: pointer;

        &:last-child {
            border-radius: 0 0 5px 5px;
        }
    }

    :global(.select__option--is-focused) {
        background: var(--container);
    }

    :global(.select__option--is-selected) {
        background: var(--primaryColor);

        &:global(.select__option--is-focused) {
            background: var(--primaryColorDark);
        }
    }

    :global(.select__option--is-disabled) {
        color: var(--gray5);
        cursor: not-allowed;
    }

    &.invalid {
        :global(.select__control) {
            border-color: var(--signalRed);
            color: var(--signalRed);

            &:hover {
                border-color: var(--signalRed);

                :global(.select__dropdown-indicator) {
                    &::before,
                    &::after {
                        background: var(--signalRedActive);
                    }
                }
            }
        }

        :global(.select__dropdown-indicator) {
            &::before,
            &::after {
                background: var(--signalRed);
            }
        }
    }
}
