.root:not(:last-child) {
    margin-bottom: calc(2 * var(--spacing));
}

.wrapper {
    min-height: 0;
    overflow-x: auto;

    &:not(:last-child) {
        margin-bottom: calc(2 * var(--spacing));
    }

    table {
        display: table;
        width: 100%;
        border-top: 1px solid var(--gray5);
        border-bottom: 1px solid var(--gray5);
        border-collapse: collapse;
        color: var(--gray1);

        &:not(:last-child) {
            margin-bottom: calc(2 * var(--spacing));
        }
    }

    th,
    td {
        overflow: hidden;
        padding-right: 8px;
        padding-left: 8px;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    thead {
        font-size: 1rem;
        font-weight: 600;
        line-height: 24px;

        tr {
            border-bottom: 1px solid var(--gray5);
            text-align: left;
        }

        th {
            padding-top: 25px;
            padding-bottom: 23px;
        }
    }

    tbody {
        overflow: hidden;
        font-size: 1rem;
        font-weight: 400;
        line-height: 21px;
        text-overflow: ellipsis;
        white-space: nowrap;

        tr {
            text-align: left;

            &:not(:last-child) {
                border-bottom: 1px solid var(--gray5);
            }
        }

        td {
            padding-top: 20px;
            padding-bottom: 17px;
        }
    }
}
