.list {
    margin: calc(3 * var(--spacing)) 0;
    list-style: none;

    .list {
        padding-left: calc(2.25 * var(--spacing));
        margin-top: 0;
        margin-bottom: 0;
    }

    li,
    li::after {
        margin-bottom: calc(1.5 * var(--spacing));
    }
}

ul.list li {
    margin-left: calc(2.25 * var(--spacing));

    &::before {
        padding-right: var(--spacing);
        margin-left: calc(-2.25 * var(--spacing));
        content: "•";
        float: left;
        font-size: calc(1rem / 16 * 30);
        line-height: 0.75;
    }
}

ol.list {
    display: table;
    counter-reset: counter;

    li {
        display: table-row;
        counter-increment: counter;
        list-style: none;

        &::before {
            display: table-cell;
            padding-right: var(--spacing);
            content: counter(counter) ".";
            font-weight: 600;
            text-align: right;
        }

        &::after {
            display: block;
            content: "";
        }
    }
}
