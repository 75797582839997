.root {
    background: var(--gray6);

    &.clickable {
        cursor: pointer;
    }
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.initials {
    dominant-baseline: central;
    fill: var(--gray1);
    font-size: calc(1rem / 16 * 42);
    text-anchor: middle;
    text-transform: uppercase;
}

.shape {
    border-radius: 50%;
    aspect-ratio: 1;
    vertical-align: middle;
}
