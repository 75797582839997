.root {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    gap: calc(2 * var(--spacing));

    &.fixed,
    &.fullWidth {
        flex-direction: row;
    }

    &.fullWidth button {
        width: 100%;
    }

    &.fill {
        flex-wrap: nowrap;

        button {
            width: 100%;
            min-width: 0;
            flex: 0 1 auto;
        }
    }

    @media (--smDown) {
        button {
            width: 100%;
        }

        &.fixed button {
            width: auto;
        }
    }
}
