.container {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--gray6);
    border-color: var(--gray6);
    background: #fff;

    &:first-child {
        border-radius: 12px 12px 0 0;
    }

    &:last-child {
        border-radius: 0 0 12px 12px;
    }

    &:not(:last-child) {
        border-bottom: none;
    }
}

.header {
    display: flex;
    align-content: center;
    justify-content: space-between;
    padding: calc(3 * var(--spacing));
    cursor: pointer;
    gap: calc(2 * var(--spacing));

    .left {
        display: flex;
        min-width: 0;
        flex-wrap: wrap;
        align-content: center;
        gap: calc(0.5 * var(--spacing)) calc(2 * var(--spacing));
    }

    .badge {
        background: var(--purple);
    }

    .arrow {
        position: relative;
        width: 16px;
        height: 16px;

        &::before,
        &::after {
            position: absolute;
            top: 50%;
            width: 10px;
            height: 2px;
            border-radius: 2px;
            background: var(--primaryColor);
            content: "";
            transition: 300ms var(--fastOutSlowIn);
        }

        &::before {
            left: 0;
            transform: rotate(45deg);
        }

        &::after {
            left: 6px;
            transform: rotate(-45deg);
        }

        &.expanded {
            &::before {
                transform: rotate(-45deg);
            }

            &::after {
                transform: rotate(45deg);
            }
        }
    }

    &:hover .arrow {
        &::before,
        &::after {
            background: var(--primaryColorDark);
        }
    }
}

.body {
    display: flex;
    flex-direction: column;
    padding: 0 calc(3 * var(--spacing)) calc(3 * var(--spacing));
    gap: calc(3 * var(--spacing));

    &.collapsed {
        padding-bottom: 0;
    }

    .participant,
    .actions {
        display: flex;
        flex-direction: column;
        gap: calc(2 * var(--spacing));
    }
}
