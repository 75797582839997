.root {
    overflow: hidden;
}

.header {
    position: relative;
    height: calc(6 * var(--spacing));
    border-radius: calc(1.5 * var(--spacing));
    background: var(--container);
}

.scrollButton {
    position: absolute;
    z-index: var(--zIndexOverlay);
    top: 0;
    bottom: 0;
    display: flex;
    width: 40px;
    align-items: center;
    color: var(--primaryColor);
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition:
        color 200ms var(--fastOutSlowIn),
        opacity 300ms var(--fastOutSlowIn);

    &:hover {
        color: var(--primaryColorDark);
    }

    &.visible {
        opacity: 1;
        pointer-events: auto;

        .arrow {
            transform: scale(1);
        }
    }

    &.left {
        left: 0;
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.85),
            #fff
        );

        .arrow {
            left: -7px;
        }
    }

    &.right {
        right: 0;
        justify-content: flex-end;
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 0.85),
            #fff
        );

        .arrow {
            right: -7px;
        }
    }
}

.arrow {
    position: relative;
    top: 1px;
    transform: scale(0);
    transition: transform 300ms var(--fastOutSlowIn);
}

.scrollable {
    position: relative;
    display: flex;
    overflow: hidden;
    height: 100%;
}

.indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: calc(1.5 * var(--spacing));
    background: var(--gray6);
    transition:
        transform 200ms var(--fastOutSlowIn),
        width 200ms var(--fastOutSlowIn);
}

.nav {
    height: 100%;
    white-space: nowrap;
}

.navItem {
    @mixin standard;
    @mixin w500;
    @mixin center;
    @mixin noWrap;
    position: relative;
    display: inline-block;
    min-width: 160px;
    max-width: 320px;
    height: 100%;
    padding: calc(1.5 * var(--spacing)) calc(3 * var(--spacing));
    border: none;
    cursor: pointer;
    transition: color 200ms var(--fastOutSlowIn);
    user-select: none;

    &:hover {
        color: var(--primaryColor);
    }

    &.active {
        color: inherit;
    }
}

.body {
    padding-top: calc(3 * var(--spacing));
}
