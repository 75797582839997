.date {
    color: var(--gray2);
}

.title {
    margin: 0 0 calc(2 * var(--spacing));
}

.operations {
    display: flex;
    flex-direction: column;
    gap: calc(2 * var(--spacing));
}

.operationTitle {
    display: flex;
    align-items: center;
    gap: calc(0.5 * var(--spacing));
}

.groupBooking {
    margin: var(--spacing) 0;
}

.groupBookingLabel {
    display: flex;
    gap: calc(0.5 * var(--spacing));
}

.inputFieldValues {
    margin-left: calc(2 * var(--spacing));
}
