.root {
    @mixin smaller;
    display: inline-flex;
    min-width: calc(3 * var(--spacing));
    height: calc(3 * var(--spacing));
    align-items: center;
    justify-content: center;
    padding: 0 var(--spacing);
    border-radius: calc(3 * var(--spacing));
    animation: popIn 300ms linear both;
    background: var(--primaryColor);
    color: #fff;
    pointer-events: none;
    user-select: none;

    &.small {
        min-width: calc(2.5 * var(--spacing));
        height: calc(2.5 * var(--spacing));
        border-radius: calc(2.5 * var(--spacing));
        font-size: calc(1rem / 16 * 12);
    }

    & > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

@keyframes popIn {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.15);
    }
    50% {
        transform: scale(1);
    }
    75% {
        transform: scale(0.85);
    }
    100% {
        transform: scale(1);
    }
}
