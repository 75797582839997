.root {
    display: flex;
    width: 500px;
    flex-direction: column;
    background: var(--surface);
}

.header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: calc(3 * var(--spacing));
    color: var(--primaryColor);
    gap: calc(3 * var(--spacing));
}

.body {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: calc(3 * var(--spacing)) calc(3 * var(--spacing)) 0;
    margin-bottom: calc(3 * var(--spacing));
    overflow-y: auto;
    overscroll-behavior-y: none;
}

.footer {
    display: flex;
    flex-direction: column;
    padding: calc(3 * var(--spacing));
}
