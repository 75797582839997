.wrapper {
    position: relative;
    display: inline-block;
    width: calc(18 * var(--spacing));
}

.input {
    padding: 0 calc(7 * var(--spacing));
    text-align: center;
}

.button {
    position: absolute;
    top: 1px;
    margin: var(--spacing);

    &.left {
        left: 0;
    }

    &.right {
        right: 0;
    }
}
