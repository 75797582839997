.root {
    display: flex;
    flex-direction: column;
    gap: calc(0.5 * var(--spacing));
}

.label {
    display: flex;
    flex-direction: column;
    gap: calc(0.5 * var(--spacing));
}

.wrapper {
    position: relative;
}

.input {
    @mixin standard;
    width: 100%;
    height: calc(6 * var(--spacing));
    align-items: center;
    padding: 0 calc(2 * var(--spacing));
    border: 1px solid var(--gray4);
    border-radius: 5px;
    appearance: none;
    background: #fff;
    caret-color: var(--primaryColor);
    color: var(--gray1);
    font-family: inherit;
    line-height: inherit;
    outline: none;
    transition:
        color 150ms var(--fastOutSlowIn),
        border-color 150ms var(--fastOutSlowIn);

    &:hover:not(:focus):not(:disabled):not(.invalid) {
        border-color: var(--gray3);
    }

    &:focus:not(:disabled) {
        border-color: var(--primaryColor);

        &::placeholder {
            opacity: 0;
        }

        & + .button {
            color: var(--primaryColor);
        }
    }

    &::placeholder {
        color: var(--gray5);
        opacity: 1;
        transition: inherit;
    }

    &[type="search"] {
        -webkit-appearance: none;

        &::-webkit-search-cancel-button,
        &::-webkit-search-decoration {
            -webkit-appearance: none;
        }
    }

    &[type="number"] {
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            margin: 0;
            -webkit-appearance: none;
        }
    }

    &[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
    }

    &[type="date"],
    &[type="password"],
    &[type="search"] {
        padding-right: calc(6 * var(--spacing));
    }

    &.invalid {
        border-color: var(--signalRed);
        color: var(--signalRed);
    }

    &:disabled {
        border-color: var(--gray5);
        color: var(--gray5);
        cursor: not-allowed;
    }
}

.button {
    position: absolute;
    right: 0;
    padding: 13px 10px;
    color: var(--primaryColor);

    &:hover,
    &:focus,
    &:active {
        color: var(--primaryColorDark);
    }

    &.invalid {
        color: var(--signalRed);

        &:hover,
        &:focus,
        &:active {
            color: var(--signalRedActive);
        }
    }
}
