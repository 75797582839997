.tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: calc(3 * var(--spacing));
    gap: calc(2 * var(--spacing));

    .badge {
        background: var(--primaryColor);
    }
}

.items {
    display: flex;
    flex-direction: column;
    gap: calc(3 * var(--spacing));
}
