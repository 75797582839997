.root {
    display: flex;
    flex-direction: column;
    gap: var(--spacing);
}

.inputs {
    display: flex;
    flex-direction: column;
    gap: calc(2 * var(--spacing));
}

.inline {
    flex-direction: row;
    flex-wrap: wrap;
}
