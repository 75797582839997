.wrapper {
    display: flex;
    align-items: center;
    gap: var(--spacing);
}

.thumbnail {
    width: calc(3 * var(--spacing));
    height: calc(3 * var(--spacing));
    cursor: pointer;
    object-fit: cover;
}
