.root {
    display: inline-flex;
    align-items: center;
    color: var(--primaryColor);
    cursor: pointer;
    gap: calc(0.75 * var(--spacing));
    outline: none;
    text-decoration: none;
    transition: color 200ms var(--easeInOut);

    &.withIcon:not(.underline),
    &.underlineOnHover,
    &.noUnderline {
        .text {
            text-decoration: none;
        }
    }

    &:hover,
    &:focus {
        color: var(--primaryColorDark);

        &:not(.noUnderline) .text {
            text-decoration: underline;
            text-decoration-thickness: 2px;
        }
    }

    img {
        vertical-align: middle;
    }
}

.icon {
    margin-top: -2px;
}

.text {
    text-decoration: underline;
    text-underline-offset: 5px;
}

.hidden {
    display: none;
}
