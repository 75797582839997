.root {
    display: flex;
    flex-direction: column;
    gap: calc(2 * var(--spacing));
}

.header {
    display: flex;
    flex-direction: column;
    gap: calc(0.5 * var(--spacing));
}

.title {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    gap: calc(0.5 * var(--spacing));
}

.date {
    color: var(--gray3);

    &:not(:last-child)::after {
        content: " | ";
        white-space: pre-wrap;
    }
}

.actions {
    display: flex;
    flex-wrap: wrap;
    gap: calc(2 * var(--spacing));

    .button {
        @mixin small;
        @mixin w400;
    }
}
.companions {
    display: flex;
    flex-direction: column;
    margin-top: var(--spacing);
    margin-left: calc(2 * var(--spacing));
    @mixin w400;
    @mixin small;
}

.confirmed {
    background: var(--signalGreen);
}

.pending {
    background: var(--purple);
}

.declined {
    background: var(--signalRed);
}
