.root {
    margin: calc(3 * var(--spacing)) 0;
}

.smallText {
    @mixin small;
    color: var(--gray2);
}

.mergeTop {
    border-bottom: none;
}

.mergeTop td {
    padding-bottom: 0;
}

.mergeBottom td {
    padding-top: 0;
}

.rightAligned {
    text-align: right;
}

.rightTopAligned {
    text-align: right;
    vertical-align: top;
}
