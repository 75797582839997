@import "normalize.css";
@import "variables.css";
@import "flatpickr.css";

html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: transparent;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    margin: 0;
    background: #fff;
    color: var(--gray1);
    font-family: var(--fontFamily);
    font-size: calc(1rem / 16 * 18);
    font-weight: 400;
    line-height: 24px;
}

body,
button {
    /* Fixes font formatting (1) etc. as circled number */
    font-variant: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    overflow-wrap: break-word;
}

h1,
h2 {
    margin-bottom: calc(3 * var(--spacing));
}

h3,
h4 {
    margin-bottom: calc(2 * var(--spacing));
}

h5,
h6 {
    margin-bottom: var(--spacing);
}

p {
    font-weight: 300;
}

p:not(:last-child) {
    margin-bottom: calc(3 * var(--spacing));
}

strong {
    font-weight: 600;
}

em {
    font-style: italic;
}

ul,
ol {
    padding: 0;
    margin: 0;
    font-weight: 300;
    list-style: none;
}
