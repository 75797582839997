.message {
    display: flex;
    color: var(--signalRed);
    gap: calc(0.5 * var(--spacing));
    @mixin small;
}

.icon {
    margin-top: 2px;
    font-size: calc(1rem / 16 * 18);
}
