.root {
    position: relative;
    display: inline-flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: 0;
    cursor: pointer;
    font-family: var(--fontFamily);
    gap: calc(0.75 * var(--spacing));
    outline: none;
    transition: all 200ms var(--easeInOut);
    user-select: none;
    white-space: nowrap;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.4;

        &:active {
            transform: none;
        }
    }
}

.icon {
    margin-top: -2px;
    font-size: calc(1rem / 16 * 18);
}

.primary,
.secondary,
.confirm,
.alert,
.cancel {
    min-width: calc(20 * var(--spacing));
    height: calc(6 * var(--spacing));
    padding: 0 calc(3 * var(--spacing));
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;

    &:active {
        transform: scale(0.98);
    }
}

.primary,
.secondary,
.confirm,
.alert {
    &.darkBackground {
        border-color: #fff;
        background: transparent;
        color: #fff;

        &:hover,
        &:focus,
        &:active {
            border-color: rgba(255, 255, 255, 0.8);
            background: rgba(255, 255, 255, 0.15);
            color: rgba(255, 255, 255, 0.8);
        }

        &:disabled {
            border-color: #fff;
            background: transparent;
            color: #fff;
        }
    }
}

.primary {
    border-color: var(--primaryColor);
    background: var(--primaryColor);
    color: #fff;

    &:hover,
    &:focus,
    &:active,
    &:disabled {
        border-color: var(--primaryColorDark);
        background: var(--primaryColorDark);
    }

    &.loading {
        color: transparent;

        .spinner {
            color: #fff;
        }
    }
}

.secondary {
    border-color: var(--primaryColor);
    background: #fff;
    color: var(--primaryColor);

    &:hover,
    &:focus,
    &:active,
    &:disabled {
        border-color: var(--primaryColorDark);
        color: var(--primaryColorDark);
    }

    &.loading {
        color: transparent;

        .spinner {
            color: var(--primaryColor);
        }

        &.darkBackground .spinner {
            color: #fff;
        }
    }
}

.confirm {
    border-color: var(--signalGreen);
    background: var(--signalGreen);
    color: #fff;

    &:hover,
    &:focus,
    &:active,
    &:disabled {
        border-color: var(--signalGreenActive);
        background: var(--signalGreenActive);
    }

    &.loading {
        color: transparent;

        .spinner {
            color: #fff;
        }
    }
}

.alert {
    border-color: var(--signalOrange);
    background: var(--signalOrange);
    color: #fff;

    &:hover,
    &:focus,
    &:active,
    &:disabled {
        border-color: var(--signalOrangeActive);
        background: var(--signalOrangeActive);
    }

    &.loading {
        color: transparent;

        .spinner {
            color: #fff;
        }
    }
}

.cancel {
    border-color: transparent;
    background: transparent;
    color: var(--primaryColor);

    &:hover,
    &:focus,
    &:active,
    &:disabled {
        background: var(--primaryColorTransparent);
        color: var(--primaryColorDark);
    }

    &.loading {
        color: transparent;

        .spinner {
            color: var(--primaryColor);
        }
    }

    &.darkBackground {
        color: #fff;

        &:hover,
        &:focus,
        &:active {
            background: rgba(255, 255, 255, 0.15);
            color: rgba(255, 255, 255, 0.8);
        }

        &:disabled {
            background: transparent;
            color: #fff;
        }

        &.loading {
            color: transparent;

            .spinner {
                color: #fff;
            }
        }
    }
}

.transparent {
    border: none;
    background: transparent;
    color: var(--primaryColor);

    &:hover,
    &:focus,
    &:active {
        color: var(--primaryColorDark);
    }

    &:disabled,
    &.loading {
        color: var(--gray1);
    }

    &.darkBackground {
        color: #fff;

        &:hover,
        &:focus,
        &:active {
            color: rgba(255, 255, 255, 0.8);
        }

        &:disabled,
        &.loading {
            color: #fff;
        }
    }
}
