.root {
    display: flex;
    flex-direction: column;
    gap: calc(0.5 * var(--spacing));
}

.label {
    display: flex;
    flex-direction: column;
    gap: calc(0.5 * var(--spacing));
}

.input {
    display: none;

    &:disabled + .wrapper {
        border-color: var(--gray5);
        color: var(--gray5);
        cursor: not-allowed;

        .icon {
            color: var(--gray5);
        }

        .thumbnail {
            opacity: 0.4;
        }
    }
}

.wrapper {
    display: flex;
    height: calc(6 * var(--spacing));
    align-items: center;
    padding: 0 calc(2 * var(--spacing));
    border: 1px solid var(--gray4);
    border-radius: 5px;
    background: #fff;
    color: var(--gray1);
    cursor: pointer;
    gap: var(--spacing);
    transition:
        color 150ms var(--fastOutSlowIn),
        border-color 150ms var(--fastOutSlowIn);

    &:hover {
        border-color: var(--gray3);

        .icon {
            color: var(--primaryColorDark);
        }
    }

    &.invalid {
        border-color: var(--signalRed);
        color: var(--signalRed);

        .icon {
            color: var(--signalRed);
        }
    }
}

.icon {
    color: var(--primaryColor);
}

.thumbnail {
    width: calc(3 * var(--spacing));
    height: calc(3 * var(--spacing));
    cursor: pointer;
    object-fit: cover;
}

.placeholder {
    color: var(--gray5);
}
