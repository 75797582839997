.root {
    position: fixed;
    z-index: var(--zIndexSticky);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    padding: 0;
    border: none;
    background: transparent;

    &[open] .container {
        transform: translateX(0);
    }
}

.container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    transform: translateY(100%);
    transition: transform 300ms var(--fastOutSlowIn);
}

.bottomSpacer {
    height: 0;
}
