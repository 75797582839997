.root {
    width: 26px;
    height: 26px;

    &.centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .svg {
        animation: rotate 2s linear infinite;

        .circle {
            animation: dash 1.5s ease-in-out infinite;
            stroke: var(--gray5);
            stroke-width: 7px;

            &.inherit {
                stroke: currentColor;
            }
        }
    }

    &.large {
        width: 52px;
        height: 52px;

        .circle {
            stroke-width: 5px;
        }
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}
