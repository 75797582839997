.root {
    position: relative;
    display: block;
    min-width: 0;
    border: 1px solid var(--gray6);
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 4px 16px rgba(51, 51, 51, 0.08);
    color: var(--gray1);
    transition: background 200ms var(--fastOutSlowIn);

    &.clickable {
        color: inherit;
        cursor: pointer;

        &:hover {
            background: var(--gray6);
            color: inherit;

            .image::after {
                background: rgba(0, 0, 0, 0.1);
            }
        }
    }
}

.card {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.image {
    position: relative;
    height: 180px;
    border-radius: 8px 8px 0 0;
    background-color: var(--gray7);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px 8px 0 0;
        background: rgba(0, 0, 0, 0);
        content: "";
        transition: background 200ms var(--fastOutSlowIn);
    }
}

.body {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: calc(3 * var(--spacing));
}

.header {
    @mixin small;
    margin-bottom: var(--spacing);
    color: var(--gray2);
}

.title {
    margin: 0;
}

.content {
    margin-top: calc(3 * var(--spacing));
}
