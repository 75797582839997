.root {
    position: fixed;
    z-index: var(--zIndexBackdrop);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    padding: 0;
    border: none;
    background: transparent;

    &[open] {
        .backdrop {
            opacity: 1;
        }

        .container {
            transform: scale(1);
        }
    }
}

.backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(4px) brightness(50%);
    opacity: 0;
    transition: opacity 300ms var(--fastOutSlowIn);
}

.container {
    display: flex;
    overflow: hidden;
    width: calc(100vw - 6 * var(--spacing));
    max-width: calc(100vw - 6 * var(--spacing));
    max-height: calc(100vh - 6 * var(--spacing));
    flex-direction: column;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    gap: calc(3 * var(--spacing));
    transform: scale(0.92);
    transition: transform 300ms var(--fastOutSlowIn);

    &.small {
        @media (--smUp) {
            width: var(--sm);
        }
    }

    @media (--mdUp) {
        width: var(--md);
    }
}

.header {
    position: relative;
    display: flex;
    flex-direction: column;
}

.image {
    object-fit: cover;
}

.title {
    padding: calc(3 * var(--spacing)) 44px 0 calc(3 * var(--spacing));
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
}

.body {
    padding: 0 calc(3 * var(--spacing)) calc(3 * var(--spacing));
    overflow-y: auto;
    overscroll-behavior-y: none;
}
