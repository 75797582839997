.content:not(:last-child) {
    margin-bottom: calc(3 * var(--spacing));
}

figure.content {
    margin: 0;

    &:not(:last-child) {
        margin-bottom: calc(2 * var(--spacing));
    }
}

.image {
    display: block;
    max-width: 100%;
}
