.root {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    padding-left: 37px;
    gap: calc(0.5 * var(--spacing));

    &.disabled {
        opacity: 0.4;

        .label {
            cursor: not-allowed;

            &:hover .icon {
                transform: scale(0) translateZ(0);
            }
        }

        .checkbox {
            cursor: not-allowed;

            &::before {
                border-color: var(--gray2);
            }
        }
    }
}

.label {
    @mixin standard;
    @mixin w400;
    cursor: pointer;

    &:hover .input {
        & + .checkbox {
            &::before {
                border-color: var(--gray2);
            }

            .icon {
                color: var(--gray1);
                transform: scale(0.5) translateZ(0);
            }
        }

        &:checked + .checkbox {
            &::before {
                border-color: var(--primaryColorDark);
            }

            .icon {
                color: var(--primaryColorDark);
                transform: scale(1) translateZ(0);
            }
        }
    }
}

.input {
    position: absolute;
    opacity: 0;

    &:checked + .checkbox {
        &::before {
            border-color: var(--primaryColor);
        }

        .icon {
            color: var(--primaryColor);
            transform: scale(1) translateZ(0);
        }
    }
}

.checkbox {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;

    &::before,
    .icon {
        position: absolute;
        transition: all 200ms var(--fastOutSlowIn);
    }

    &::before {
        width: 22px;
        height: 22px;
        border: 1px solid var(--gray2);
        border-radius: 5px;
        content: "";
    }

    .icon {
        top: 3px;
        left: 3px;
        color: var(--gray2);
        font-size: 1rem;
        transform: scale(0) translateZ(0);
    }

    &.invalid::before {
        border-color: var(--signalRed);
    }
}
