.root {
    position: relative;
    overflow: hidden;
    height: 74px;
    background: var(--signalBlue);
    transition: all 400ms cubic-bezier(0.35, 0.035, 0.275, 0.9);

    &:hover {
        background: var(--signalBlueActive);
    }

    &.closed {
        height: 0;
    }
}

.confirmation {
    background: var(--signalGreen);

    &:hover {
        background: var(--signalGreenActive);
    }
}

.alert {
    background: var(--signalOrange);

    &:hover {
        background: var(--signalOrangeActive);
    }
}

.content {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 74px;
    align-items: center;
    justify-content: space-between;
    padding: 0 calc(3 * var(--spacing));
    animation: fadeIn 300ms 400ms forwards cubic-bezier(0.35, 0.035, 0.275, 0.9);
    gap: calc(3 * var(--spacing));
    opacity: 0;

    @media (--mdUp) {
        padding: 0 calc(4.5 * var(--spacing));
        gap: calc(4.5 * var(--spacing));
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.body {
    overflow: hidden;
    flex: 1;
    color: #fff;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
}
