.root {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(2 * var(--spacing));
}

.current {
    font-weight: 700;
}
