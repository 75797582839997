.form {
    display: flex;
    flex-direction: column;
    gap: calc(3 * var(--spacing));
}

.fields {
    display: flex;
    flex-direction: column;
    gap: calc(2 * var(--spacing));
}

.participants {
    display: flex;
    flex-direction: column;
    padding-left: calc(3.5 * var(--spacing));
    gap: var(--spacing);
    list-style: decimal;
}

.primaryParticipant {
    color: var(--gray4);
}

.participant {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing);
}
