.root {
    position: fixed;
    z-index: var(--zIndexBackdrop);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    padding: 0;
    border: none;
    background: transparent;

    &[open] {
        .backdrop {
            backdrop-filter: blur(4px) brightness(50%) opacity(100%);
        }

        .container {
            transform: translateX(0);
        }
    }
}

.backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(4px) brightness(50%) opacity(0);
    transition: backdrop-filter 300ms var(--fastOutSlowIn);
}

.container {
    position: absolute;
    top: 0;
    left: 0;
    max-width: calc(100vw - 6 * var(--spacing));
    height: 100%;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    overscroll-behavior-y: none;
    transform: translateX(-100%);
    transition: transform 300ms var(--fastOutSlowIn);

    &.right {
        right: 0;
        left: auto;
        transform: translateX(100%);
    }
}
