.root {
    .body {
        display: flex;
        padding: calc(3 * var(--spacing)) calc(3 * var(--spacing)) 0;
        gap: calc(2 * var(--spacing));

        .avatar {
            border-radius: 50%;
            box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
        }

        .comment {
            display: flex;
            flex: 1;
            flex-direction: column;
            gap: var(--spacing);

            .text {
                padding: calc(2 * var(--spacing));
                border: 1px solid var(--gray6);
                border-radius: 5px;
                background: #fff;
                box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
            }

            .footer {
                display: flex;
                color: var(--gray3);
                gap: var(--spacing);

                .separator {
                    color: var(--gray6);
                }

                .button {
                    overflow: initial;
                    @mixin small;
                }
            }
        }
    }

    &.collapsed .body {
        padding-top: 0;
    }
}
