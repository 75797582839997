.icon {
    &.s1 {
        font-size: 1rem;
    }

    &.s2 {
        font-size: calc(1rem / 16 * 24);
    }

    &.s3 {
        font-size: calc(1rem / 16 * 32);
    }

    &.s4 {
        font-size: calc(1rem / 16 * 40);
    }

    &.s5 {
        font-size: calc(1rem / 16 * 48);
    }

    &.s6 {
        font-size: calc(1rem / 16 * 56);
    }
}
