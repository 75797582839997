.card {
    &:only-child {
        @media (--lgUp) {
            width: calc(50% - 25.75px);
        }
    }

    &:not(:only-child) {
        margin-left: calc(6 * var(--spacing));

        .date::before {
            position: absolute;
            z-index: var(--zIndexOverlay);
            left: -58px;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background: var(--gray6);
            content: "";
            transition: background 200ms var(--fastOutSlowIn);
        }

        &:not(:first-child):not(:last-child) {
            .image::before,
            .body::before {
                position: absolute;
                top: 0;
                left: -28px;
                width: 3px;
                height: calc(100% + 32px);
                background: var(--gray6);
                content: "";
            }
        }

        &:first-child {
            .body::before {
                position: absolute;
                top: 32px;
                left: -28px;
                width: 3px;
                height: 100%;
                background: var(--gray6);
                content: "";
            }
        }

        &:last-child {
            .image::before,
            .body::before {
                position: absolute;
                top: 0;
                left: -28px;
                width: 3px;
                background: var(--gray6);
                content: "";
            }

            .image::before {
                height: 100%;
            }

            .body::before {
                height: 32px;
            }
        }

        &:hover .date::before {
            background: var(--primaryColor);
        }

        @media (--lgUp) {
            width: calc(50% - 25.75px);
            margin-left: 0;

            &:not(:first-child) {
                margin-top: calc(-10 * var(--spacing));
            }

            &:nth-child(odd) {
                margin-left: 0;

                .date::before {
                    right: -58px;
                    left: auto;
                }

                &:not(:first-child):not(:last-child) {
                    .image::before,
                    .body::before {
                        right: -28px;
                        left: auto;
                    }
                }

                &:first-child {
                    .body::before {
                        right: -28px;
                        left: auto;
                    }
                }

                &:last-child {
                    .image::before,
                    .body::before {
                        right: -28px;
                        left: auto;
                    }

                    .body::after {
                        position: absolute;
                        top: 32px;
                        right: -28px;
                        width: 3px;
                        height: calc(100% - 32px);
                        background: #fff;
                        content: "";
                    }
                }
            }

            &:nth-child(even) {
                align-self: flex-end;

                &:last-child .body::after {
                    position: absolute;
                    top: 32px;
                    left: -28px;
                    width: 3px;
                    height: calc(100% - 32px);
                    background: #fff;
                    content: "";
                }
            }
        }
    }
}

.body {
    position: relative;
}

.header {
    display: flex;
    flex-direction: column;
    gap: calc(0.5 * var(--spacing));

    .row {
        position: relative;
        display: flex;
        align-items: center;
        gap: calc(0.5 * var(--spacing));
    }
}

.tagBadge {
    background: var(--secondaryColor);
}

.content {
    display: flex;
    flex-direction: column;
    gap: calc(3 * var(--spacing));
}

.speakers {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing);
}
