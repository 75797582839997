.root {
    display: inline-flex;
    overflow: hidden;
    align-items: center;
    padding: 6px 12px;
    border: 1px solid var(--signalGray);
    border-radius: 18px;
    background: #fff;
    color: var(--gray4);
    cursor: pointer;
    font-family: inherit;
    gap: var(--spacing);
    text-overflow: ellipsis;
    transition: all 70ms var(--easeInOut);
    white-space: nowrap;

    &:hover,
    &:focus,
    &:active {
        border: 1px solid var(--signalGrayActive);
        color: var(--primaryColor);
    }

    &:active {
        transform: scale(0.98);
    }

    &.selected {
        border-color: var(--primaryColor);
        color: var(--primaryColor);

        &:hover,
        &:focus,
        &:active {
            border-color: var(--primaryColor);
        }
    }
}
