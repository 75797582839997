.toggle {
    display: inline-flex;
    align-items: center;
    color: var(--primaryColor);
    gap: calc(0.75 * var(--spacing));
    user-select: none;

    &:hover {
        color: var(--primaryColorDark);
    }

    &:not(.openOnHover) {
        cursor: pointer;
    }
}

.content {
    position: absolute;
    z-index: calc(var(--zIndexSticky) + 1);
    top: -999px;
    left: -999px;
    padding: calc(3 * var(--spacing));
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transform: translateY(0);
    transition:
        opacity 300ms var(--fastOutSlowIn),
        transform 300ms var(--fastOutSlowIn);
    --arrowLeft: auto;
    --arrowRight: auto;

    &.visible {
        opacity: 1;

        &.fromTop {
            transform: translateY(16px);
        }

        &.fromBottom {
            transform: translateY(-16px);
        }
    }

    &::after {
        position: absolute;
        right: var(--arrowRight);
        left: var(--arrowLeft);
        width: 14px;
        height: 14px;
        background: #fff;
        content: "";
        transform: rotate(45deg);
    }

    &.fromTop::after {
        top: -7px;
        box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.15);
    }

    &.fromBottom::after {
        bottom: -7px;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
    }
}

.list {
    min-width: 254px;
    padding: 0;

    & > * {
        display: block;
        padding: calc(1.5 * var(--spacing)) calc(3 * var(--spacing));
        color: var(--primaryColor);
        cursor: pointer;
        text-align: center;
        transition: none;

        &:first-child {
            border-radius: 8px 8px 0 0;
        }

        &:last-child {
            border-radius: 0 0 8px 8px;
        }

        &:not(:last-child) {
            border-bottom: 1px solid var(--gray7);
        }

        &:hover {
            background: var(--primaryColor);
            color: #fff;
        }
    }

    &::after {
        content: none;
    }

    &.fromTop > :first-child {
        &::after {
            position: absolute;
            top: -7px;
            right: var(--arrowRight);
            left: var(--arrowLeft);
            width: 14px;
            height: 14px;
            background: #fff;
            box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.15);
            content: "";
            transform: rotate(45deg);
        }

        &:hover::after {
            background: var(--primaryColor);
        }
    }

    &.fromBottom > :last-child {
        &::after {
            position: absolute;
            right: var(--arrowRight);
            bottom: -7px;
            left: var(--arrowLeft);
            width: 14px;
            height: 14px;
            background: #fff;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
            content: "";
            transform: rotate(45deg);
        }

        &:hover::after {
            background: var(--primaryColor);
        }
    }
}
