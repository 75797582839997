.root {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    padding-left: 69px;
    gap: calc(0.5 * var(--spacing));

    &.disabled {
        opacity: 0.4;

        .label,
        .switch {
            cursor: not-allowed;
        }
    }

    &:not(.disabled) {
        .label:hover .input,
        .label .input:focus {
            & + .switch::before {
                background-color: var(--gray5);
            }

            &:checked + .switch::before {
                background: var(--primaryColorDark);
            }
        }
    }
}

.label {
    @mixin standard;
    cursor: pointer;
}

.input {
    position: absolute;
    opacity: 0;

    &:checked + .switch {
        &::before {
            background: var(--primaryColor);
        }

        &::after {
            left: 33px;
        }
    }
}

.switch {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;

    &::before,
    &::after {
        position: absolute;
        content: "";
        transition: all 200ms var(--fastOutSlowIn);
    }

    &::before {
        top: 0;
        left: 0;
        width: 54px;
        height: 24px;
        border-radius: 24px;
        background: var(--gray6);
    }

    &::after {
        top: 3px;
        left: 3px;
        width: 18px;
        height: 18px;
        border-radius: 18px;
        background: #fff;
    }
}
