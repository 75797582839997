.root {
    padding-right: 4px;
    padding-left: 4px;
    margin-right: calc(24px - 4px);
    margin-left: calc(24px - 4px);

    @media (--mdUp) {
        width: 744px;
        padding-right: 8px;
        padding-left: 8px;
        margin-right: auto;
        margin-left: auto;
    }

    @media (--lgUp) {
        width: 972px;
        padding-right: 12px;
        padding-left: 12px;
    }

    @media (--xlUp) {
        width: 1224px;
        padding-right: 12px;
        padding-left: 12px;
    }

    @media (--ulUp) {
        width: 1380px;
        padding-right: 12px;
        padding-left: 12px;
    }
}
