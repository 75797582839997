.root {
    height: 100%;
}

.player {
    height: 100%;

    &:global(.jw-skin-zoon .jw-controlbar .jw-button-container) {
        padding: 0;
    }

    &:global(.jw-skin-zoon .jw-controlbar .jw-button-container .jw-spacer) {
        display: none;
    }
}

.messageOverlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    padding: calc(3 * var(--spacing));
    background: var(--primaryColor);
    color: #fff;
    gap: calc(1.5 * var(--spacing));
}

.messageText {
    &:last-child {
        margin: 0;
    }

    @media (--lgUp) {
        width: 75%;
    }

    @media (--xlUp) {
        width: 50%;
    }
}
