.root {
    display: flex;
    flex-direction: column;
    margin-left: calc(2 * var(--spacing));
    gap: var(--spacing);
    @mixin small;
    @mixin w400;
}

.groupBooking {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: calc(0.5 * var(--spacing));

    .button {
        @mixin small;
        @mixin w400;
    }
}

.companion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: calc(0.5 * var(--spacing));
}
